<template>
    <div ref="chartContainer" class="chart" style="height: 100%; position: relative">
    <!-- <div class="chart" style="height: 100%; position: relative"> -->
        <!-- <div style="position: absolute; top: -10%;left: 10%; z-index: 2;">
            <button @click="exportXlsx()"> Xlsx</button>
            <button @click="exportSvg()"> Svg</button>
        </div> -->
    </div>

    <ExportSettingsDialog 
        :algo="'something4'"
        @exportToSvg="exportToSvgEmit"
    />


</template>

<script>
/* eslint-disable */

import Anychart from 'anychart'
import ExportSettingsDialog from './ExportSettingsDialog.vue'
import './AnychartThemes/market_forecast.js'

export default {
    props: [
        'options', 
        'years', 
        'data',
        'total',  
        'segmentationItems', 
        'output', 
        'segmentationVersusTitles',
        'topicName',
        'segmentationName',
        'valueDisplayOption',
        'index1',
        'exportToWord',
        'segmentationItemsFullName',
    ],
    emits: ['next-step','addChartsForExportWord'],
    name: 'VueAnychart',
    components:{
        ExportSettingsDialog
    },
    data() {
        return {
            chart: null,
            column: null,
        }
    },
    mounted () {
        // if (!this.chart && this.options) {
        //     this.init();
        // }
        this.init();
        this.$emit('next-step')
        
        // VALIDATE IF IS FROM EXPORT TO WORD PAGE
        if(this.exportToWord){
            this.addChartsForWord()
        }
    },

    computed: {
        topic() {
            return this.$store.getters.obtainTopic;
        },
    },
    methods: {
        removeSeries() {
            if (this.chart.getSeriesCount()) {
                this.chart.removeSeriesAt(0);
            }
        },
        removeAllSeries() {
            if (this.chart.getSeriesCount()) {
                this.chart.removeAllSeries();
            }
        },
        addSeries(data) {
            this.delegateMethod('addSeries', data);
        },
        delegateMethod(name, data) {
            if (!this.chart) {
                warn(`Cannot call [$name] before the chart is initialized. Set prop [options] first.`, this);
                return
            }

            return this.chart[name](data)
        },

        init(){
            let _Anychart = this.Anychart || Anychart
            this.chart = _Anychart;

            // construct Data for set 

            const years = this.years; 
            const dataValues = this.data;
            const segmItems = this.segmentationItems;
            let total = [...this.total];
            total.shift();
            total.pop(); 

            // format To Parse
            for (let i = 0; i < total.length; i++) {
                const num = parseFloat(total[i]);
                total[i] = isNaN(num) ? total[i]  : num;
            }

            let forSetData = []; 

            years.map((year, index) => {
                let Array = []
                Array.push(year);
                dataValues[index].map(data => {
                    Array.push(data);
                })
                Array.push(total[index])
                forSetData.push(Array);
            })

            // Set Data
            let dataSet = this.chart.data.set(forSetData); 

            // create Bar chart 
            this.column = this.chart.column() 

            // APPLY THEME 
            this.chart.theme('marketForecast');

            // LICENSE KEY
            this.chart.licenseKey('marketforecast.com-f857f242-9dccc95c'); 

            // CREDITS 
            const currentYear = new Date().getFullYear();
            this.column
                .credits() 
                .enabled(true)
                .url('https://www.marketforecast.com')
                .logoSrc('https://www.marketforecast.com/favicon.ico')
                .text(
                    `© Market Forecast ${currentYear}`
                );

            
            // Force chart to stack values by Y scale

            this.column.yScale().stackMode('value');

            // Title
            // column title // hide column Title
            // this.column
            //     .title()
            //     .enabled(true)
            //     .useHtml(true)
            //     .padding([0,0,10,0])
            //     .text(
            //         `<span style="font-size: 16px;font-weight:bold;">${this.topicName} Market to ${ years[years.length -1 ]} by ${this.segmentationName}</span>` +
            //         '<br/><span style="color:#929292; font-size: 12px;">' +
            //         `Market Forecast ${years[0]} - ${years[years.length -1 ]}</span>`
            //     );

            // Set chart margin settings
            // this.column.padding(20, 20, 10, 20);
            this.column.padding(20, 5, 5, 5);
            
            // Grid settings
            this.column
                .yGrid(true)
                // .xGrid(true)
                .xGrid(false)
                // .xMinorGrid(true)
                .xMinorGrid(false)
                .yMinorGrid(true)
            
            // Set chart legend Settigs 
            this.column.legend().enabled(true).padding({top:10, bottom:30})
            this.column.legend().position("bottom");
            this.column.legend().itemsLayout("horizontal-expandable");

            let segmentationItemsFullName = this.segmentationItemsFullName

            this.column.legend().itemsFormatter(function(items){
                return items.map(
                    function(item, index){
                        // add custom LegendTitle
                        if(index === items.length - 1  ){
                            item.text = "Total";
                        }
                        else{
                            item.text = segmentationItemsFullName[index];
                        }
                        return item
                    }
                )
            })

            this.column.yAxis(0)
                .title("Market size in US$ "+this.valueDisplayOption)
                .title().padding(10)
                .fontColor("#385D8A") //Change color of Y-axis title
            
            this.column.yAxis(0)
                .labels().format(
                    function(){
                        return Anychart.format.number(this.value, {
                            groupsSeparator:','
                        });
                    }
                )
            

                
            // CREATE A LINE SERIES FOR TOTAL MARKET FORECAST
            // CREATE THIS LINE AS FIRST LINE  - THIS WILL MAKE THE MARKET A CIRCLE If is Spline 
            // CREATE A LINE SERIES FOR TOTAL MARKET FORECAST
            // let totalSeriesData = dataSet.mapAs({x:0, value:forSetData[0].length - 1}) //get length of first index  
            // let totalSeriesForecast = this.column.spline(totalSeriesData);
            // totalSeriesForecast.name('Total Market Forecast'); // Rename the totalSeriesForecast

            // // Add Labels to totalSeriesForecast with format 
            // totalSeriesForecast.labels()
            //     .enabled(true)
            //     .format(
            //         function(){
            //             return Anychart.format.number(this.value, {
            //                 groupsSeparator: ','
            //             });
            //         }
            //     )
            //     .anchor('center-bottom')
            //     .offsetY(10) // Adjust offsetY to position the label above the column
            //     .padding(5)
            //     .background().enabled(true).fill("#FFFFFF"); //Add Background color 

            // Helper function to setup label settings for all series 
            let setupSeriesLabels = function (series, name){
                series.name(name).stroke('1 #fff 1');
                series.hovered().stroke('1 #fff 1');

                series.normal().stroke("#ffffff", 1, "", "");
                series.hovered().stroke("#ffffff", 1, "", "");
                series.selected().stroke("#ffffff", 1, "", "");

                // Set opacity for different states,
                series.normal().fill(function(){
                    return this.sourceColor + ' 1'; // Keep color with opacity 1
                });
                series.hovered().fill(function(){
                    return this.sourceColor + ' 0.8'; // Keep color with opacity 0.8
                });

                series.selected().fill(function(){
                    return this.sourceColor + ' 0.6'; // Keep color with opacity 0.6
                });
            }

            // SERIES USING ABREVIATION 
            // set dinamic mapData ,  create dinamic series
            // segmItems.forEach((item, index ) => {
            //     const segmSeriesData = dataSet.mapAs({x:0, value:index + 1})
            //     const series = this.column.column(segmSeriesData)
            //     setupSeriesLabels(series, item)
            // });

            segmentationItemsFullName.forEach((item, index ) => {
                const segmSeriesData = dataSet.mapAs({x:0, value:index + 1})
                const series = this.column.column(segmSeriesData)
                setupSeriesLabels(series, item)
            });

            // Set Global toolTip styles
            this.column.tooltip().title().fontColor('#FFFFFF'); // set tooltip text color
            this.column.tooltip().title().fontSize(14); // set tooltip fontSize
            this.column.tooltip().fontColor("#FFFFFF") // set tooltip text Color
            this.column.tooltip().fontSize(12); //Set tooltip font size
            this.column.tooltip().padding(10); // Set tooltip padding

            // Customize the tooltip title and content 

            const valueDisplayOption = this.valueDisplayOption

            this.column.tooltip()
                .titleFormat(
                    function(){
                        return 'Market Forecast: ' + this.points[0].x;
                    }
                )
                .format(
                    function(){
                        return this.seriesName+' : ' + Anychart.format.number(this.value, {
                            groupsSeparator:',',
                            decimalSeparator: '.'
                        })+` US$ ${valueDisplayOption}`;
                    }
                )

            // Set interactivity hover
            this.column.interactivity().hoverMode('by-y');
            this.column.tooltip().valuePrefix('$').displayMode('union');

            // Marker
            let totalSeriesData = dataSet.mapAs({x:0, value:forSetData[0].length - 1}) //get length of first index  
            let totalSeriesForecast = this.column.marker(totalSeriesData);
            totalSeriesForecast.name('Total'); // Rename the totalSeriesForecast
            totalSeriesForecast.normal().type("circle");

            // Add Labels to totalSeriesForecast with format 
            totalSeriesForecast.labels()
                .enabled(true)
                .format(
                    function(){
                        return Anychart.format.number(this.value, {
                            groupsSeparator: ','
                        });
                    }
                )
                .anchor('center-bottom')
                .offsetY(10) // Adjust offsetY to position the label above the column
                .padding(5)
                .background().enabled(true).fill("#FFFFFF"); //Add Background color 
            

            this.column.container(this.$refs.chartContainer).draw();

            this.column.contextMenu().itemsFormatter(
                function(items){
                    // modify save as png
                    items['save-chart-as'].subMenu['save-chart-as-png'].action = function() {
                        this.column.saveAsPng(
                            {
                                "width": 1000,
                                "height": 1000,
                                "quality": 0.3,
                                "filename":'ST01_'+this.topicName+'_Market_to_'+this.years[this.years.length -1]+'_by_'+this.segmentationName,
                            }
                        );
                    }.bind(this); 
                    // modify save as jpg
                    items['save-chart-as'].subMenu['save-chart-as-jpg'].action = function() {
                        this.column.saveAsJpg(
                            {
                                "width": 1000,
                                "height": 1000,
                                "quality": 0.3,
                                "forceTransparentWhite": false,
                                "filename":'ST01_'+this.topicName+'_Market_to_'+this.years[this.years.length -1]+'_by_'+this.segmentationName,
                            }
                        );
                    }.bind(this); 

                    // modify save as pdf
                    items['save-chart-as'].subMenu['save-chart-as-svg'].action = function() {
                        this.column.saveAsSvg(
                            // {
                            //     "paperSize": 'a4',
                            //     "landscape": true,
                            // }
                            {
                                "width": 1000,
                                "height": 1000,
                                "filename":'ST01_'+this.topicName+'_Market_to_'+this.years[this.years.length -1]+'_by_'+this.segmentationName,
                            }
                        );
                    }.bind(this); 


                    // modify save as pdf
                    items['save-chart-as'].subMenu['save-chart-as-pdf'].action = function() {
                        this.column.saveAsPdf(
                            {
                                "paperSize": 'a4',
                                "landscape": true,
                            }
                        );
                    }.bind(this); 
                    return items;
                }.bind(this)
            )
            
            // this.chart.exports.filename(this.topicName+' '+'Market summary by'+' '+ this.segmentationName); //change name of exports  
            this.chart.exports.filename('ST01_'+this.topicName+'_Market_to_'+this.years[this.years.length -1]+'_by_'+this.segmentationName); //change name of exports  

        },

        exportToSvgEmit({width, height}){
            this.exportToSvg(width, height)
        },

        exportToSvg(width, height){
            this.column.saveAsSvg(
                {
                    "width":width,
                    "height":height,
                    "filename":'ST01_'+this.topicName+'_Market_to_'+this.years[this.years.length -1]+'_by_'+this.segmentationName,
                }
            )
        },

        // exportXlsx() {
        //     this.chart.column().saveAsXlsx();

        // },
        
        // exportPng() {
        //     // console.log(this.chart);
        //     // this.chart.column().saveAsPng({
        //     //     "width": 360,
        //     //     "height": 500,
        //     //     "quality": 0.3,
        //     //     "filename": 'filename',
        //     // })


        //     this.chart.column().saveAsSvg(
        //         {
        //             "width": 360,
        //             "height": 500,
        //             "filename": 'My Chart SVG'
        //         }
        //     )

        //     // this.chart.saveAsPng({
        //     //     "width": 360,
        //     //     "height": 500,
        //     //     "quality": 0.3,
        //     //     "filename": this.options.chart.title,
        //     // });

        //     // this.chart.saveAsPng({
        //     //     "width": 360,
        //     //     "height": 500,
        //     //     "quality": 0.3,
        //     //     "filename": 'aaaa',
        //     // });



        // },

        // exportSvg() {
        //     this.column.saveAsSvg(
        //         {
        //             "width": 360,
        //             "height": 500,
        //             "filename": 'My Chart SVG'
        //             // name: topic + segmversus
        //             // name: topic + segmversus on title

        //         }
        //     )
        //     // console.log('termino de pasar Algo');

        // },

        // export To Doc
        addChartsForWord(){
            this.column.width(600);
            this.column.height(400);

            // export Chart to svg with customize size 
            let svg = this.column.toSvg();

            // Convert svg string to an DOM object
            let parser = new DOMParser();
            let svgDoc = parser.parseFromString(svg, 'image/svg+xml');

            // get root <svg >
            let svgElement = svgDoc.documentElement;

            // change width and height 
            svgElement.setAttribute('width', '600');
            svgElement.setAttribute('height', '400');

            // serialize svg to string 
            let serializer = new XMLSerializer();
            let svgString = serializer.serializeToString(svgElement);
            
            const exportContent = {
                // content: this.column.toSvg(),
                content: svgString,
                name: 'singleSegmentation '+'Figure 2.1',
                segmentation: this.segmentationName,
                order: this.index1
            } ;

            this.$emit("addChartsForExportWord", {exportContent: exportContent, order:exportContent.order})
        },

        async exportToDoc() {
            let svgChart = this.column.toSvg();

            this.svgToPng(svgChart, (pngDataUrl) => {
                const preHtml = `
                <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
                <head><meta charset='utf-8'><title>Export HTML to Word Document</title></head><body>`;
                const postHtml = "</body></html>";

                // Inserta la imagen PNG en lugar del SVG
                const html = `${preHtml}<img src="${pngDataUrl}" />${postHtml}`;

                const blob = new Blob(['\ufeff', html], {
                    type: 'application/msword'
                });

                // Especifica el nombre del archivo
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'exported-document.doc';
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(url);
            });
        },



    },
    watch: {
        // options: function (options) {
        //     if (!this.chart && options) {
        //         this.init()
        //     } else {
        //         this.chart.dispose();
        //         this.chart = null;
        //         this.init();
        //     }
        // }
        // 'output': {
        //     async handler(newVal) {
        //         if (newVal) {
        //             // console.log('outputFrom Chart Change', newVal);
        //             if (this.chart) {
        //                 this.column.dispose();
        //                 this.chart = null;
        //                 this.column = null;
        //                 this.init();
        //             }
        //         }
        //     },
        //     deep: true,
        // },
        // 'data':{
        //     async handler(newVal){
        //         if( newVal ){
        //             if (this.chart) {
        //                 this.column.dispose();
        //                 this.chart = null;
        //                 this.column = null;
        //                 this.init();
        //             }
        //         }
        //     }
        // }
    },
    beforeUnmount() {
        if (this.chart) {
            this.column.dispose();
            this.chart = null;
            this.column = null;
        }
    }
    // beforeDestroy() {
    //     if (this.chart) {
    //         this.chart.dispose();
    //         this.chart = null;
    //     }
    // }
}
</script>

<style scoped>
.colorTest {
    background-color: #385D8A;
    background-color: #26CDA1;
}
</style>
